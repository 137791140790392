import { Button, Grid } from '@mui/material';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ScreenHeader } from '../components/screenHeader';
import {
    fetchComplains,
    getComplain,
    updateComplain,
    deleteComplain,
    previousPage,
    nextPage,
    resetFilter,
    setFilter,
    checkItem,
    checkAllItems,
    uncheckItem,
    uncheckAllItems,
    sendAgainToCompany,
    removeComplain,
    closeComplain,
    changeVisibilityIsBlocked,
} from "../app/slices/complainsSlice";
import {
    getConsumer,
} from '../app/slices/consumersSlice';
import {
    getCompany,
} from '../app/slices/companiesSlice';
import {
    fetchLogs,
    nextPage as nextPageLogs,
    previousPage as previousPageLogs,
    resetFilter as resetFilterLogs,
    setFilter as setFilterLogs,
} from "../app/slices/logsSlice";
import { fetchComplainTypes } from '../app/slices/complainTypesSlice';
import { showModal } from '../app/slices/modalSlice';
import { withRouter } from '../wrappers/withRouter';
import { ConsumerInfoForm } from '../components/forms/consumer';
import { DeleteOutline } from '@mui/icons-material';
import { ComplainAdminsComment, ComplainAttachments, ComplainDataForm, ComplainDuplicateList, ComplainHistory, ComplainMessages, ComplainStatusForm } from '../components/forms/complain';
import { CompanyAdditionalInfoForm, CompanyInfoForm } from '../components/forms/company';
import { PrivateIconWithBg, PublicIconWithBg } from '../assets/icons';
import { format } from 'date-fns';
import { withTranslation } from 'react-i18next';
import ComplainLogsList from '../components/forms/complain/complainLogsList';

class ComplainEditScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {
            id: this.props.params.id,
            isStatusChangeRequested: false,
        }

        this.props.resetFilter();
        this.refresh();

        this.refresh = this.refresh.bind(this);
        this.refreshComplains = this.refreshComplains.bind(this);
        this.handleFieldSave = this.handleFieldSave.bind(this);
        this.handlePreviousPage = this.handlePreviousPage.bind(this);
        this.handleNextPage = this.handleNextPage.bind(this);
        this.handleRequestSort = this.handleRequestSort.bind(this);
        this.handleRowClick = this.handleRowClick.bind(this);
        this.handleSelectAll = this.handleSelectAll.bind(this);
        this.handleStatusChange = this.handleStatusChange.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleSendAgainToCompany = this.handleSendAgainToCompany.bind(this);
        this.handleCloseComplaint = this.handleCloseComplaint.bind(this);
        this.handleChangeVisibilityIsBlocked = this.handleChangeVisibilityIsBlocked.bind(this);
        this.handleRowCheck = this.handleRowCheck.bind(this);
        this.handlePreviousPageLogs = this.handlePreviousPageLogs.bind(this);
        this.handleNextPageLogs = this.handleNextPageLogs.bind(this);
        this.handleRequestSortLogs = this.handleRequestSortLogs.bind(this);
    }

    componentDidUpdate() {
        if (this.props.params.id !== this.state.id) {
            this.setState({
                id: this.props.params.id,
            })

            this.refresh().then(() => {
                window.scrollTo(0, 0);
            });
        }
    }

    async refresh() {
        await this.props.getComplain(this.props.params.id);

        if (this.props.model?.company?.id) {
            await this.props.getCompany(this.props.model.company.id);
        }

        await this.props.fetchComplainTypes(this.props.sector);

        if (this.props.model?.reporter?.id) {
            await this.props.getConsumer(this.props.model.reporter.id);
        }

        await this.refreshComplains();
        await this.refreshLogs();
    }

    async refreshComplains() {
        await this.props.fetchComplains({ parentId: this.props.params.id });
    }

    async refreshLogs() {
        await this.props.fetchLogs({ complaintId: this.props.params.id });
    }

    async handleFieldSave(field, value, isStatus) {
        try {
            await this.props.updateComplain({
                id: this.props.params.id,
                fieldName: field,
                value: value + "",
                isStatusEdit: !!isStatus,
            });

            if (isStatus) {
                this.setState({
                    isStatusChangeRequested: false,
                });
            }
        } catch (e) {
            console.log('e :>> ', e);
        }

        await this.refresh();
    }

    handlePreviousPage() {
        this.props.previousPage();
        this.refreshComplains();
    }

    handleNextPage() {
        this.props.nextPage();
        this.refreshComplains();
    }

    handleRequestSort(event, property) {
        const direction =
            this.props.filter.orderBy === property &&
            this.props.filter.orderDirection === "asc"
                ? "desc"
                : "asc";
        this.props.setFilter({ field: "orderBy", value: property });
        this.props.setFilter({ field: "orderDirection", value: direction });

        this.refreshComplains();
    }

    handlePreviousPageLogs() {
        this.props.previousPageLogs();
        this.refreshLogs();
    }

    handleNextPageLogs() {
        this.props.nextPageLogs();
        this.refreshLogs();
    }

    handleRequestSortLogs(event, property) {
        const direction =
            this.props.logsFilter.orderBy === property &&
            this.props.logsFilter.orderDirection === "asc"
                ? "desc"
                : "asc";
        this.props.setFilterLogs({ field: "orderBy", value: property });
        this.props.setFilterLogs({ field: "orderDirection", value: direction });

        this.refreshLogs();
    }

    handleRowCheck(item, isChecked) {
        if (!isChecked) {
            this.props.checkItem(item);
        } else {
            this.props.uncheckItem(item);
        }
    }

    handleSelectAll() {
        if (this.props.selectedItems.length === this.props.complains.length) {
            this.props.uncheckAllItems();
        } else {
            this.props.checkAllItems();
        }
    }

    handleRowClick(id) {
        this.setState({
            open: this.state.open === id ? null : id,
        });
    }

    async handleSendAgainToCompany() {
        let result = await this.props.sendAgainToCompany(this.props.params.id);

        if (!result.hasOwnProperty('error')) {
            this.props.showModal({
                title: this.props.t_modals('ComplaintResendSuccess'),
                mode: 'normal',
            });
        } else {
            this.props.showModal({
                title: this.props.t_modals('ComplaintResendError'),
                mode: 'danger',
            });
        }
    }

    async handleChangeVisibilityIsBlocked() {
        await this.props.changeVisibilityIsBlocked(this.props.params.id);

        this.refresh();
    }

    handleStatusChange() {
        this.setState({
            isStatusChangeRequested: true,
        });
    }

    handleDelete() {
        this.props.showModal({
            title: this.props.t_modals('ComplaintDeleteTitle'),
            message: `${this.props.t_modals('ComplaintDeleteMessage')} ${this.props.model.number}?`,
            button: this.props.t_buttons('delete'),
            mode: 'normal',
            cancelButton: true,
            fallback: () => {
                this.props.removeComplain(this.props.model.id).then((data) => {
                    if (data.payload?.status === 200) {
                        this.props.showModal({
                            message: this.props.t_modals('ComplaintDeleteSuccess'),
                            mode: 'normal',
                            fallback: () => {
                                this.props.navigate('/complains');
                            }
                        });

                    } else {
                        this.props.showModal({
                            message: this.props.t_modals('ComplaintDeleteError'),
                            mode: 'danger',
                        });
                    }
                });
            }
        });
    }

    handleCloseComplaint() {
        this.props.showModal({
            message: 'Czy problem został rozwiązany?',
            mode: 'normal',
            yesNoButtons: true,
            fallbackYes: () => {
                this.props.closeComplain({
                    id: this.props.model.id,
                    isSuccess: true,
                }).then(async () => {
                    await this.refresh();
                });
            },
            fallbackNo: () => {
                this.props.closeComplain({
                    id: this.props.model.id,
                    isSuccess: false,
                }).then(async () => {
                    await this.refresh();
                });
            }
        });
    }

    render() {
        if (!this.props.model?.id)
            return;

        return (
            <div className="fk-screen">
                <ScreenHeader
                    withBackButton
                    withSeparator
                    title="complainHeader"
                    titleReplacement={['#COMPLAIN_NUMBER#', this.props.model.number]}
                    subtitle={format(
                        new Date(this.props.model.firstCreatedAt),
                        "yyyy-MM-dd 'godz:' HH:mm"
                    )}
                    additionalButtons={
                        <div className="screen-header-buttons">
                            <div className={`fk-change-complain-visibility ${this.props.model.isPublic ? '--public' : '--private'}`}>
                                <img src={this.props.model.isPublic ? PublicIconWithBg : PrivateIconWithBg} alt="" />
                                {this.props.model.isPublic ? 'Publiczna' : 'Prywatna'}
                                <Button className="green" onClick={async () => await this.handleFieldSave('IsPublic', !this.props.model.isPublic)}>[{this.props.t_buttons('change')}]</Button>
                            </div>
                            <div className="right-side">
                                <div className="button-with-label">
                                    <label>{this.props.model.changeVisibilityBlocked ? this.props.t_buttons('visibilityChangeIsBlocked') : this.props.t_buttons('visibilityChangeIsAvailable')}</label>
                                    <Button className="green" onClick={this.handleChangeVisibilityIsBlocked}>[{this.props.t_buttons('change')}]</Button>
                                </div>
                                <Button className="green" onClick={this.handleSendAgainToCompany}>{this.props.t_buttons('sendAgainToCompany')}</Button>
                                <Button className="green" onClick={this.handleStatusChange}>{this.props.t_buttons('changeComplainStatus')}</Button>
                                {!this.props.model?.isResolved &&
                                    <Button className="green" onClick={this.handleCloseComplaint}>{this.props.t_buttons('closeComplain')}</Button>
                                }
                                <Button className="red" onClick={this.handleDelete}><DeleteOutline /> {this.props.t_buttons('delete')}</Button>
                            </div>
                        </div>
                    }
                />
                <Grid container spacing={4}>
                    <Grid item md={6}>
                        <ComplainStatusForm onUpdate={this.handleFieldSave} isEditRequested={this.state.isStatusChangeRequested}/>
                        <ComplainDataForm onUpdate={this.handleFieldSave} />
                        <ComplainAttachments onUpdate={this.refresh} />
                    </Grid>
                    <Grid item md={6}>
                        <ConsumerInfoForm />
                        <CompanyInfoForm />
                        <CompanyAdditionalInfoForm />
                    </Grid>
                </Grid>
                <ComplainDuplicateList
                    parentId={this.props.model.id}
                    selectedItems={this.props.selectedItems}
                    onRequestSort={this.handleRequestSort}
                    onRowCheck={this.handleRowCheck}
                    onSelectAll={this.handleSelectAll}
                    onPreviousPage={this.handlePreviousPage}
                    onNextPage={this.handleNextPage}
                />
                <ComplainMessages onUpdate={this.refresh}/>
                <ComplainAdminsComment onUpdate={this.handleFieldSave} />
                <ComplainHistory />
                <ComplainLogsList
                    onDetails
                    onRequestSort={this.handleRequestSortLogs}
                    onPreviousPage={this.handlePreviousPageLogs}
                    onNextPage={this.handleNextPageLogs}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    model: state.complains.complain,
    complains: state.complains.data,
    filter: state.complains.filter,
    selectedItems: state.complains.selectedItems,
    sector: state.companies.company.sectorId,
    logsFilter: state.logs.filter,
});

const actions = {
    fetchComplains,
    getComplain,
    getCompany,
    getConsumer,
    previousPage,
    nextPage,
    resetFilter,
    setFilter,
    checkItem,
    checkAllItems,
    uncheckItem,
    uncheckAllItems,
    updateComplain,
    deleteComplain,
    sendAgainToCompany,
    removeComplain,
    showModal,
    fetchComplainTypes,
    closeComplain,
    changeVisibilityIsBlocked,
    fetchLogs,
    previousPageLogs,
    nextPageLogs,
    resetFilterLogs,
    setFilterLogs,
};

export default withTranslation()(withRouter(connect(mapStateToProps, actions)(ComplainEditScreen)));